<template>
<Base-Layout PageTitle="CONTROLE D'ACCES" page-default-back-Link="/accuiel">
    <br>


    <div v-if="this.ID_RFID_CLIENT==null" style="color:white">
        <center>
            <img src="assets/images/nodata2.png" style=" object-fit: cover;margin-top:150px;width:350px" />
        </center>
        <ion-card router-link="/accuiel" style="--ion-background-color: #1d1d1f;  margin:10px">
            <ion-card-content style="padding:12px;padding-left:20px">
                <center>
                    Accueil
                </center>
            </ion-card-content>
        </ion-card>
    </div>

    <div v-if="this.ID_RFID_CLIENT!=null" style="color:white">
    <span class="first-text" style="font-size:14px;color:#d2d2d2">QR CODE
    </span><br>

<br><br>

                
                 <ion-thumbnail class="item-avatar" item-start style="width:350px;height:350px;margin:0px">
                    <img :src="this.QRCODE_URL">
                  
                </ion-thumbnail>
            </div>
  

</Base-Layout>
</template>

<script>



import {toastController } from '@ionic/vue';
import {ipServerObjet as ipServerObjet} from './Configserver.js';

export default {
    data() {
        return {
            presentingElement: null,
            ipServer: '',
            qrGeneratorLink:'',
            QRCODE_URL:'',
            ID_RFID_CLIENT:'',

        };
    },
    async created() {
        for (const property in ipServerObjet) {
            this.ipServer = ipServerObjet[property].ip;
            this.qrGeneratorLink = ipServerObjet[property].qrGeneratorLink;
        }


        this.axios({
            url: ''+this.ipServer+'/api/PROFIL_CLIENT/' + localStorage.getItem('carte'),
                    method: 'get',
                    timeout: 15000,
                }).then(response => {
                this.dataSource = response.data;
                this.ID_RFID_CLIENT = this.dataSource[0].ID_RFID_CLIENT;
                

                if(this.ID_RFID_CLIENT != null)
                {
                    this.QRCODE_URL = ""+this.qrGeneratorLink+"?id="+this.ID_RFID_CLIENT;
                }
     
               

                }).catch(async () => {
                    this.NoConnexion();
                });


  

    },
    methods: {

        async NoConnexion() {
            const toast = await toastController.create({
                message: 'Oups ! Il semble y avoir un problème avec votre connexion Internet',
                duration: 2000,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        }
     
    },
    components: {
        

  
    }
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}
.clickcard:active {
    background: #FFE01E;
    color: #0e0e0f;
    border-top: 1px solid #22262e;
}

.clickcard:active .first-text {

    --color: #0e0e0f;

}
.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}
</style>
